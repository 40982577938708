$color-linkedIn: #0056b3;

$color-bg1: #003452;
$color-bg2: #001a30;
$color-bg3: #09263e;

$color-mtb130: #092237;
$color-mtb120: #083048;
$color-mtb100: #0b3d5b;
$color-mtb70: #54778c;
$color-mtb40: #9db1bd;
$color-mtb20: #ced8de;
$color-mtb10: #e6ebee;

$color-mtg120: #1acc8e;
$color-mtg115: #1cd997;
$color-mtg100: #21ffb2;
$color-mtg70: #63ffc9;
$color-mtg60: #90ffd9;
$color-mtg40: #a6ffe0;
$color-mtg20: #d3fff0;

$color-grey100: #000;
$color-grey90: #191919;
$color-grey80: #333333;
$color-grey70: #4c4c4c;
$color-grey60: #666666;
$color-grey50: #7f7f7f;
$color-grey40: #999999;
$color-grey30: #b2b2b2;
$color-grey20: #cccccc;
$color-grey15: #d9d9d9;
$color-grey12: #f8f8f8;
$color-grey11: #dde4e8;
$color-grey10: #f6f7f8;
$color-grey5: #f2f2f2;
$color-grey2: #eff3f4;
$color-grey0: #fff;

$color-neutrals-grey-10: #e5e5e5;
$color-neutrals-grey-20: #cccccc;
$color-neutrals-grey-30: #b2b2b2;
$color-neutrals-grey-40: #999;

$color-orange: #f46a1b;
$color-orange-light: #f5813e;
$color-orange-dark: #d14c00;
$color-orange-hover: #ff7e35;

$color-func-info120: #1064be;
$color-func-info100: #157dee;

$border-radius: 3px;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-l: 18px;
$font-size-xl: 20px;
$font-size-xxl: 22px;
$font-size-xxl-1: 24px;
$font-size-xxl-2: 30px;

$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 20px;
$spacing-xl: 24px;
$spacing-xxl: 28px;
$spacing-xxl-2: 32px;
$spacing-xxl-3: 36px;
$spacing-xxl-4: 40px;
$spacing-xxl-5: 44px;
$spacing-xxl-6: 48px;
$spacing-xxl-7: 52px;
$spacing-xxl-8: 56px;
