@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';
@import '../../../styles/mixins.scss';

.panelWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-grey0;
}

.heroContainer {
  position: relative;
  width: 100%;
  height: 650px;
  overflow: hidden;
}

.heroVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 1;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  pointer-events: none; /* so it doesn't block interactions */
}

.heroContent {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  padding: 2rem;
  top: 40%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: $color-grey0;
    font-size: 32px;
  }

  h2 {
    color: $color-mtg100;
    font-size: 30px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: $color-grey0;
    max-width: 500px;
    font-weight: 400;
    margin-top: 24px;
  }
}

.buttons {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  padding: 2rem;
  top: 30%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;

  a {
    width: 180px;
  }

  @include media-mobile-m {
    flex-direction: column;
    align-items: center;
    a {
      margin: 0 0 16px 0 !important;
      width: 70vw;
    }
  }
}
