@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';
@import '../../../styles/mixins.scss';

.solutionsWrapper {
  color: $color-grey0;
  padding: 24px 0;
  text-align: center;

  h2 {
    color: $color-grey0;
    font-size: 24px;
  }
  p {
    color: $color-grey0;
    padding: 14px 0;
    max-width: 650px;
  }
}

.solutions {
  width: 100%;
  gap: 40px;
  padding: 18px;
}

.solution {
  border: 1px solid $color-grey0;
  padding: 10px;
  border-radius: 20px;
  max-width: 230px;
  flex: 1 1 230px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-mobile-l {
    flex-basis: 90%;
  }
  h3 {
    font-size: 22px !important;
    text-align: center;
    font-weight: 500;
    color: $color-grey0;
  }
  .divider {
    width: 70px;
    height: 7px;
    margin: 10px auto;
    border-radius: 10px;
    background-color: $color-mtg100;
  }
  p {
    text-align: center;
    font-size: 14px;
  }
  img {
    margin: 10px 0;
  }
}
