@mixin media-laptop-XLarge {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin media-laptop-large {
  @media screen and (min-width: 1300px) {
    @content;
  }
}

@mixin media-laptop-normal {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin media-laptop-s {
  @media screen and (max-width: 1050px) {
    @content;
  }
}

@mixin media-laptop {
  @media screen and (min-width: 993px) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin media-mobile-l {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin media-mobile-m {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin media-mobile-s {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin media-mobile-xs {
  @media screen and (max-width: 360px) {
    @content;
  }
}
