@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';
@import '../../../styles/mixins.scss';

.panelWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-grey0;
}
.stats {
  padding: 24px 0;
  text-align: center;
  color: $color-mtb100;
}

.wrapper {
  display: flex;
  flex-wrap: wrap; /* enables wrapping */
  justify-content: center; /* optional alignment */

  @include media-mobile-l {
    flex-direction: column;
    align-items: center;
  }
}

.card {
  display: grid;
  grid-template-rows: 80px 40px auto;
  height: 170px;
  align-items: center;
  width: 140px;
  padding: 0 10px;

  @include media-mobile-l {
    padding: 10px;
    height: 190px;
  }
}

.card:not(:last-child) {
  border-right: 2px solid $color-mtg100;
  border-bottom: none;

  @include media-mobile-l {
    border-right: none;
    border-bottom: 2px solid $color-mtg100;
  }
}

.img {
  width: 65px;
  height: auto;
  margin: 0 auto;
}

.label {
  font-size: 24px;
  font-weight: 500;
}

.desc {
  font-size: 14px;
}

.cta {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
}

.cta button {
  background: #4be4af;
  border: none;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  color: #001e36;
  cursor: pointer;
}
